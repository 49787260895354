import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchServer } from 'mastodon/actions/server';
import { ServerHeroImage } from 'mastodon/components/server_hero_image';
import { ShortNumber } from 'mastodon/components/short_number';
import { Skeleton } from 'mastodon/components/skeleton';
import Account from 'mastodon/containers/account_container';
import { domain } from 'mastodon/initial_state';
import api from 'mastodon/api';
import { importFetchedAccount } from 'mastodon/actions/importer';

const styles = `
  .server-banner__accounts {
    padding: 20px 15px;
    border-top: 1px solid var(--brand-color--faint);
    max-height: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .server-banner__accounts-header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    color: var(--primary-text-color--faint);
    flex-shrink: 0;
  }

  .server-banner__accounts-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
  }

  .server-banner__accounts-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .server-banner__accounts-list::-webkit-scrollbar-thumb {
    background: var(--brand-color--faint);
    border-radius: 4px;
  }

  .server-banner__accounts-list::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Increased specificity to override parent styles */
  .server-banner .server-banner__accounts-list .account {
    padding: 16px !important; /* Using !important as a last resort if still needed */
    border-bottom: 1px solid lighten($ui-base-color, 12%);
  }

  .server-banner .server-banner__accounts-list .account:hover {
    background: var(--background-color--hover);
  }

  .server-banner .server-banner__accounts-list .account:last-child {
    border-bottom: none;
  }

  /* Skeleton styles with matching specificity */
  .server-banner .server-banner__accounts-list .skeleton-wrapper {
    padding: 16px;
    border-bottom: 1px solid var(--brand-color--faint);
  }

  .server-banner .server-banner__accounts-list .skeleton-wrapper:last-child {
    border-bottom: none;
  }
`;

const messages = defineMessages({
  aboutActiveUsers: { id: 'server_banner.about_active_users', defaultMessage: 'People using this server during the last 30 days (Monthly Active Users)' },
  featuredAccounts: { id: 'server_banner.featured_accounts', defaultMessage: 'Featured Accounts' },
});

const accountIds = [
  '113611845628715045',
  // '113611731300494825',
  // '113611719047614013',
  '113611712284624859',
  '113611706391989348',
  '113611702239825105',
  '113611679061280440',
  '113611670542577943',
  '113611662115628705',
  '113611655431460143'
  // '113611598683926538',
  // '113611584130807461'
];

const mapStateToProps = state => ({
  server: state.getIn(['server', 'server']),
});

class ServerBanner extends PureComponent {
  static propTypes = {
    server: PropTypes.object,
    dispatch: PropTypes.func,
    intl: PropTypes.object,
  };

  state = {
    loadedAccounts: new Set(),
    isLoadingAccounts: true,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchServer());
    
    // Inject styles
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    // Fetch accounts data
    this.fetchAccounts();
  }

  fetchAccounts = async () => {
    const { dispatch } = this.props;

    try {
      // Fetch accounts in parallel
      const promises = accountIds.map(id => 
        api().get(`/api/v1/accounts/${id}`)
          .then(response => {
            dispatch(importFetchedAccount(response.data));
            this.setState(prevState => ({
              loadedAccounts: new Set([...prevState.loadedAccounts, id])
            }));
            return response.data;
          })
          .catch(error => {
            console.error(`Error fetching account ${id}:`, error);
            return null;
          })
      );

      await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    } finally {
      this.setState({ isLoadingAccounts: false });
    }
  };

  renderAccounts = () => {
    const { loadedAccounts, isLoadingAccounts } = this.state;

    if (isLoadingAccounts) {
      return (
        <div className='server-banner__accounts'>
          <h4 className='server-banner__accounts-header'>
            <FormattedMessage id='server_banner.featured_accounts' defaultMessage='Featured Accounts' />
          </h4>
          <div className='server-banner__accounts-list'>
            {[...Array(4)].map((_, index) => (
              <div key={index} style={{ padding: '8px' }}>
                <Skeleton width='100%' height='36px' />
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className='server-banner__accounts'>
        <h4 className='server-banner__accounts-header' style={{marginTop: '10px'}}>
          <FormattedMessage id='server_banner.featured_accounts' defaultMessage='Featured Accounts' />
        </h4>
        <div className='server-banner__accounts-list' style={{ maxHeight: '210px', overflowY: 'scroll' }}>
          {accountIds.map((id, index) => 
            loadedAccounts.has(id) ? (
              <div 
              key={`${id}-${index}`}
              style={{
                padding: '16px',
                borderBottom: '1px solid #c0cdd9',
              }}
            >
              <Account
                key={`${id}-${index}`} 
                id={id} 
                size={36} 
                minimal
              />
              </div>
            ) : null
          )}
        </div>
      </div>
    );
  };

  render() {
    const { server, intl } = this.props;
    const isLoading = server.get('isLoading');

    return (
      <div className='server-banner'>
        <div className='server-banner__introduction'>
          <FormattedMessage id='server_banner.introduction' defaultMessage='{domain} is part of the decentralized social network powered by {mastodon}.' values={{ domain: <strong>{domain}</strong>, mastodon: <a href='https://joinmastodon.org' target='_blank'>Mastodon</a> }} />
        </div>

        <ServerHeroImage blurhash={server.getIn(['thumbnail', 'blurhash'])} src={server.getIn(['thumbnail', 'url'])} className='server-banner__hero' />

        <div className='server-banner__description' style={{ direction: 'rtl' }}>
          {isLoading ? (
            <>
              <Skeleton width='100%' />
              <br />
              <Skeleton width='100%' />
              <br />
              <Skeleton width='70%' />
            </>
          ) : server.get('description')}
        </div>

        <div className='server-banner__meta'>
          <div className='server-banner__meta__column'>
            <h4><FormattedMessage id='server_banner.administered_by' defaultMessage='Administered by:' /></h4>
            <Account id={server.getIn(['contact', 'account', 'id'])} size={36} minimal />
          </div>

          <div className='server-banner__meta__column'>
            <h4><FormattedMessage id='server_banner.server_stats' defaultMessage='Server stats:' /></h4>
            {isLoading ? (
              <>
                <strong className='server-banner__number'><Skeleton width='10ch' /></strong>
                <br />
                <span className='server-banner__number-label'><Skeleton width='5ch' /></span>
              </>
            ) : (
              <>
                <strong className='server-banner__number'><ShortNumber value={server.getIn(['usage', 'users', 'active_month'])} /></strong>
                <br />
                <span className='server-banner__number-label' title={intl.formatMessage(messages.aboutActiveUsers)}><FormattedMessage id='server_banner.active_users' defaultMessage='active users' /></span>
              </>
            )}
          </div>
        </div>

        <hr className='spacer' />

        <Link className='button button--block button-secondary' to='/about'>
          <FormattedMessage id='server_banner.learn_more' defaultMessage='Learn more' />
        </Link>

        {this.renderAccounts()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(injectIntl(ServerBanner));