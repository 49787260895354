import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { changeComposing, mountCompose, unmountCompose } from 'mastodon/actions/compose';
import ServerBanner from 'mastodon/components/server_banner';
import ComposeFormContainer from 'mastodon/features/compose/containers/compose_form_container';
import NavigationContainer from 'mastodon/features/compose/containers/navigation_container';
import SearchContainer from 'mastodon/features/compose/containers/search_container';
import Account from 'mastodon/containers/account_container';
import LinkFooter from './link_footer';

const styles = `
  .compose-panel__accounts {
    padding: 10px 15px;
    border-top: 1px solid var(--brand-color--faint);
    max-height: 300px;
    display: flex;
    flex-direction: column;
  }

  .compose-panel__accounts-header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--primary-text-color--faint);
    flex-shrink: 0;
  }

  .compose-panel__accounts-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    padding-right: 10px;
    margin-right: -10px;
  }

  .compose-panel__accounts-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .compose-panel__accounts-list::-webkit-scrollbar-thumb {
    background: var(--brand-color--faint);
    border-radius: 4px;
  }

  .compose-panel__accounts-list::-webkit-scrollbar-track {
    background: transparent;
  }

  .compose-panel__accounts-list .account {
    padding: 8px;
    border-radius: 4px;
    background: var(--background-color);
    transition: background 0.2s ease;
  }

  .compose-panel__accounts-list .account:hover {
    background: var(--background-color--hover);
  }
`;

const accountIds = [
  '113611845628715045',
  // '113611731300494825',
  // '113611719047614013',
  '113611712284624859',
  '113611706391989348',
  '113611702239825105',
  '113611679061280440',
  '113611670542577943',
  '113611662115628705',
  '113611655431460143'
  // '113611598683926538',
  // '113611584130807461'
];

const mapStateToProps = state => ({
  accounts: state.get('accounts')
});

class ComposePanel extends PureComponent {
  static contextTypes = {
    identity: PropTypes.object.isRequired,
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    accounts: PropTypes.object
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(mountCompose());
    
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(unmountCompose());
  }

  onFocus = () => {
    const { dispatch } = this.props;
    dispatch(changeComposing(true));
  };

  onBlur = () => {
    const { dispatch } = this.props;
    dispatch(changeComposing(false));
  };

  renderAccounts = () => {
    return (
      <div className='compose-panel__accounts'>
        <h4 className='compose-panel__accounts-header'>Featured Accounts</h4>
        <div className='compose-panel__accounts-list' style={{ maxHeight: '350px', overflowY: 'scroll' }}>
          {accountIds.map((id, index) => (
            <Account 
              key={`${id}-${index}`} 
              id={id} 
              size={36} 
              minimal
            />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { signedIn } = this.context.identity;

    return (
      <div className='compose-panel' onFocus={this.onFocus}>
        <SearchContainer openInRoute />

        {!signedIn && (
          <>
            <ServerBanner />
            <div className='flex-spacer' />
          </>
        )}

        {signedIn && (
          <>
            <NavigationContainer onClose={this.onBlur} />
            <ComposeFormContainer singleColumn />
            {this.renderAccounts()}
          </>
        )}

        <LinkFooter />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ComposePanel);